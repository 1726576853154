<template>
  <div id="user-profile">
    <profile-header :header-data="profileData" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="12"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about :about-data="profileAbout" />
          <!-- <profile-suggested-pages :pages-data="profileData" />
          <profile-twitter-feed :twitter-feed="profileData" /> -->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
          <!-- <b-col
            lg="6"
            cols="12"
            order="1"
            order-lg="2"
          >
            <profile-post :posts="profileData.post" />
          </b-col> -->
        <!-- post -->

        <!-- latest photos suggestion and polls -->
          <!-- <b-col
            lg="3"
            cols="12"
            order="3"
          >
            <profile-latest-photos :latest-images="profileData" />
            <profile-suggestion :suggestions="profileData" />
            <profile-polls :polls-data="profileData" />
          </b-col> -->
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
          <!-- <b-col
            cols="12"
            order="4"
          >
            <profile-bottom />
          </b-col> -->
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
import ProfilePost from './ProfilePost.vue'
import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
import ProfileSuggestion from './ProfileSuggestion.vue'
import ProfilePolls from './ProfilePolls.vue'
import profileBottom from './profileBottom.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,

    ProfileHeader,
    ProfileAbout,
    ProfileSuggestedPages,
    ProfileTwitterFeed,
    ProfilePost,
    ProfileLatestPhotos,
    ProfileSuggestion,
    ProfilePolls,
    profileBottom,
  },
  data() {
    return {
      profileData: { },
    }
  },
  created() {
    this.profileData = JSON.parse(localStorage.userData)
    this.profileAbout = {...this.profileData}
    delete this.profileAbout.auth
    delete this.profileAbout.token
    delete this.profileAbout.id
    delete this.profileAbout.uuid
    delete this.profileAbout.typeUuid
    delete this.profileAbout.userDetail
    delete this.profileAbout.azienda
    this.profileAbout["nome"] = this.profileData.userDetail.name
    this.profileAbout["cognome"] = this.profileData.userDetail.surname
    this.profileAbout["permessi"] = localStorage.role
    // this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
