<template>
  <b-card>
    <!-- about -->
    <div
      v-for="(data,key,index) in aboutData"
      :key="index"
      :class="index ? 'mt-2':''"
    >
      <template  v-if="data">
        <h5 class="text-capitalize mb-75">
          {{ key }}
        </h5>
        <b-card-text>
          {{new Date(data) != 'Invalid Date' ? new Date(data).toLocaleString('it-IT', dateOptions) : data}}
        </b-card-text>
      </template>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
    }
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
